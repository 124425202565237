<template>
    <div class="art-detail-box">
        <h3>{{detailInfo.activityName}}</h3>
        <p class="art-detail-time">
            <span class="el-icon-time">起始：{{detailInfo.activityEndTimeStr}}</span>~
            <span>截止：{{detailInfo.activityTimeStr}}</span>
        </p>
        <div class="cd_banner">
            <h2>活动图: <span>点击观看原始高清图</span> </h2>
            <el-image
                    style="width: 100px; height: 100px"
                    :src="setUrl(detailInfo.showImg)"
                    :preview-src-list="srcList">
            </el-image>
        </div>
        <h2 class="cd_tagline">活动详情</h2>
        <div class="intro_cont">
            <div v-for="item in intro">
                <img :src="setUrl(item.cont)" alt="" v-if="item.type=='img'">
                <p v-else>{{item.cont}}</p>
            </div>
        </div>
        <div v-if="detailType==2" style="text-align: right;">
            <el-button type="danger" @click="lookJoin">查看参团</el-button>
            <el-button type="primary" @click="lookComment">查看评论</el-button>
        </div>
        <div class="joinList" v-if="dialogJoin">
            <div v-for="item in joinList" class="joinMin">
                <img :src="setUrl(item.joinUserPhoto)" alt="">
                <span>{{item.joinUserName}}</span>
            </div>
            <div style="text-align: center;margin-top:20px;">
                <el-button @click="dialogJoin = false" size="mini" type="primary">关 闭</el-button>
            </div>
        </div>
        <div class="joinList" v-if="dialogComment">
            <h2>活动留念</h2>
            <div class="final">
                <p v-if="final">活动小结：{{final.content}}</p>
                <p v-else>暂未总结</p>
                <el-carousel :interval="4000" type="card" height="300px" v-if="showImg.length!=0">
                    <el-carousel-item v-for="(item,index) of showImg" :key="index">
                        <img :src="setUrl(item)">
                    </el-carousel-item>
                </el-carousel>
            </div>
            <div v-for="(item,index) in commentList" class="commentList">
                <img :src="setUrl(item.commentUserPhoto)" alt="">
                <div class="commentMin">
                    <span>{{item.commentUserName}}</span>
                    <p>{{item.content}}</p>
                    <div v-if="item.showImg && item.item!=''" v-for="img in item.showImg.split(',')">
                        <el-image
                                style="width: 100px; height: 100px;margin-right:10px;"
                                :src="setUrl(img)"
                                :preview-src-list="getImgList(index)">
                        </el-image>
                    </div>
                    <time>{{item.addTimeStr}}</time>
                </div>
            </div>
            <div style="text-align: center;margin-top:20px;">
                <el-button @click="dialogComment = false" size="mini" type="primary">关 闭</el-button>
            </div>
        </div>
    </div>
</template>

<script>
    import instance from '@/services/instance'
    export default {
        name: "activityDetail",
        props: {
            detailInfo: {
                type: Object,
                required: true,
            },
            detailType: {
                type:Number,
                required: true,
            },
        },
        data(){
            return {
                joinList:[],
                dialogJoin:false,
                commentList:[],
                dialogComment:false,
                showImg:[],
                final:null,
                srcList:[this.setUrl(this.detailInfo.showImg)],
                intro:JSON.parse(this.detailInfo.activityContent),
            }
        },
        created() {
            console.log(this.detailInfo)
        },
        methods: {
            setUrl(url){
                return "/officialWebsiteMgr/resource/file/getImage?fileName="+url;
            },
            getImgList(index){
                var showImg=this.commentList[index].showImg.split(",");
                let arr=[];
                for (let i=0; i < showImg.length; i++) {
                    arr.push(this.setUrl(this.commentList[index].showImg))
                }
                return arr;

            },
           lookJoin(){
                this.dialogJoin=true;
                instance
                    .post('/officialWebsiteMgr/resource/activity/joinList',{activityId: this.detailInfo.activityId,status: 0,joinUser: ""})
                    .then((res) => {
                        if (res.code == 0) {
                            this.joinList=res.data.list;
                        }
                    })
            },
            lookComment(){
                this.dialogComment=true;
                //评论列表
                instance
                    .post('/officialWebsiteMgr/resource/activity/commentList',{activityId: this.detailInfo.activityId})
                    .then((res) => {
                        if (res.code == 0) {
                            this.commentList=res.data.list;
                            if(this.commentList[0].showImg!=""){
                                this.commentScr=this.setUrl(this.commentList[0].showImg.split(","));
                            }
                        }
                    })
                //总结报告
                instance
                    .post('/officialWebsiteMgr/resource/activity/actCrtCmtDetal',{activityId: this.detailInfo.activityId})
                    .then((res) => {
                        if (res.code == 0) {
                            this.final=res.data;
                            this.showImg=this.final.showImg.split(",");
                        }
                    })
            },
        },
    }
</script>

<style scoped>
    .cd_banner h2,.cd_tagline {
        font-size: 16px;
        color: #999;
        border-bottom: 1px solid #999;
        margin:20px 0 10px;
        padding-bottom: 5px;
    }
    .cd_banner h2 span {
        font-size: 14px;
        font-weight: normal;
    }
    .intro_cont img{
        margin:10px 0;
    }
    .intro_cont p{
        line-height: 24px;
    }
    .joinList{
        width:100%;
        height:100%;
        background:#fff;
        position: absolute;
        left:0;
        top:0;
        padding:1px 20px 10px;
        box-sizing: border-box;
    }
    .joinList>h2{
        font-size: 14px;
        text-align: center;
        line-height: 50px;
    }
    .joinMin{
        display:flex;
        align-items: center;
        height:40px;
        margin-top:10px;
    }
    .joinMin img{
        width:30px;
        height:30px;
        -webkit-border-radius: 25%;
        -moz-border-radius: 25%;
        border-radius: 25%;
        margin-right:20px;
    }
    .final>p{
        margin-bottom:20px;
    }
    .commentList{
        display: flex;
        border-bottom: solid 1px #eee;
        margin-bottom: 20px;
        padding-bottom: 10px;
    }
    .commentList>img{
        width:50px;
        height:50px;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        border-radius: 5px;
    }
    .commentMin{
        margin-left:10px;
    }
    .commentMin span{
        color: #69F;
        font-size: 14px;
        font-weight: bold;
    }
    .commentMin p{
        font-size: 14px;
        line-height: 18px;
        padding-right: 10px;
        margin: 8px 0;
    }
    .commentMin time{
        color: #999;
        font-size: 14px;
    }
</style>