<template>
    <div>
        <div class="my_artlist_box">
            <div style="text-align: right;margin-bottom:20px;">
                <span style="float:left;line-height:40px;color:#409EFF;" v-if="isShow">我发布的活动</span>
                <span style="float:left;line-height:40px;color:#409EFF;" v-else>我参与的活动</span>
                <el-button type="danger" @click="isShow=true">我发布的</el-button>
                <el-button type="primary" @click="isShow=false">我参与的</el-button>
            </div>
            <div v-if="tableData.length!=0 && isShow">
                <ul class="my_artlist_ul">
                    <li v-for="(item,index) of tableData" :key=item.activityId>
                        <div class="my_artlist_point_img">
                            <img :src="setUrl(item.showImg)" alt="">
                        </div>
                        <div class="my_actlist_point" @click="goDetail(item.activityId,1)">
                            <h2>{{item.activityName}}</h2>
                            <p class="activityDesc">{{item.activityDesc}}</p>
                            <p>
                                <span :class="'st'+item.activityStatus">{{ statusTxtAry[item.activityStatus] }}</span>
                            </p>
                            <p>
                                <span>起始：{{item.activityTimeStr}}</span>
                                <span>截止：{{item.activityEndTimeStr}}</span>
                            </p>
                        </div>
                        <div class="my_artlist_point_btn">
                            <div v-if="item.activityStatus=='0'">
                                <span class="el-icon-edit"  @click="goPub(item.activityId,index)">发布</span>
                            </div>
                            <div v-if="item.activityStatus=='0'">
                                <span class="el-icon-edit"  @click="goEdit(item.activityId)">编辑</span>
                            </div>
                            <div v-if="['1', '4'].includes(item.activityStatus.toString())">
                                <span class="el-icon-discover" @click="goRecord(item.activityId)">统计</span>
                            </div>
                            <div v-if="['1', '4'].includes(item.activityStatus.toString())">
                                <span class="el-icon-circle-close" @click="close(item.activityId)">关闭</span>
                            </div>
                            <div v-if="['2', '4'].includes(item.activityStatus.toString())">
                                <span class="el-icon-s-operation" @click="evaluate(item.activityId,item.activityName)">总评</span>
                            </div>
                        </div>
                    </li>
                </ul>
                <div class="pagestyle">
                    <el-pagination
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                            :current-page="currentPage"
                            :page-sizes="[10, 20]"
                            :page-size="pageSize"
                            layout="total, sizes, prev, pager, next, jumper"
                            :total="total"
                    >
                    </el-pagination>
                </div>
            </div>
            <div v-if="tableData.length==0 && isShow" class="artnote">
                <p>还没有发布活动，<router-link to="/mySpace/activityCreate/create/0">快去发布吧</router-link></p>
            </div>
            <div v-if="tableData1.length!=0 && !isShow">
                <ul class="my_artlist_ul">
                    <li v-for="item of tableData1" :key=item.activityId>
                        <div class="my_artlist_point_img">
                            <img :src="setUrl(item.showImg)" alt="">
                        </div>
                        <div class="my_actlist_point" @click="goDetail(item.activityId,2)">
                            <h2>{{item.activityName}}</h2>
                            <p>{{item.activityDesc}}</p>
                            <p>
                                <span :class="'st'+item.activityStatus">{{ statusTxtAry[item.activityStatus] }}</span>
                            </p>
                            <p>
                                <span>起始：{{item.activityTimeStr}}</span>
                                <span>截止：{{item.activityEndTimeStr}}</span>
                            </p>
                        </div>
                    </li>
                </ul>
                <div class="pagestyle">
                    <el-pagination
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                            :current-page="currentPage1"
                            :page-sizes="[10, 20]"
                            :page-size="pageSize1"
                            layout="total, sizes, prev, pager, next, jumper"
                            :total="total1"
                    >
                    </el-pagination>
                </div>
            </div>
            <div v-if="tableData1.length==0 && !isShow" class="artnote">
                <p>还没有参加过活动，<router-link to="/activity">快去选一个吧</router-link></p>
            </div>
        </div>
        <el-dialog
            title="活动总结"
            :visible.sync="dialogVisible"
            width="50%"
            :before-close="finalClose">
            <div class="activity">
                <div>
                    活动：{{title}}
                </div>
                <div>
                    <p style="padding-bottom: 20px;">图集：</p>
                    <el-upload
                            :show-file-list="true"
                            action="/officialWebsiteMgr/resource/file/addImage"
                            list-type="picture-card"
                            :on-success="successImg"
                            :on-remove="handleRemove"
                            :file-list="fileList"
                            ref="upload">
                        <i slot="default" class="el-icon-plus"></i>
                    </el-upload>
                </div>
                <div>
                    总结：
                    <el-input type="textarea" :rows="3" v-model="content"></el-input>
                </div>
            </div>
            <div style="text-align: center;margin-top:20px;">
                <el-button @click="finalClose">关 闭</el-button>
                <el-button type="primary" @click="save">保 存</el-button>
            </div>
        </el-dialog>
        <el-dialog :visible.sync="dialogVisibleDetail" width="850px">
            <detail-box :detailInfo="detailCont" :detailType="1"></detail-box>
            <div slot="footer" class="dialog-footer">
                <el-button
                        size="mini"
                        type="primary"
                        @click="dialogVisibleDetail = false"
                >关 闭</el-button
                >
            </div>
        </el-dialog>
        <el-dialog :visible.sync="dialogVisibleDetail1" width="850px">
            <detail-box :detailInfo="detailCont" :detailType="2"></detail-box>
            <div slot="footer" class="dialog-footer">
                <el-button
                        size="mini"
                        type="primary"
                        @click="dialogVisibleDetail1 = false"
                >关 闭</el-button
                >
            </div>
        </el-dialog>
    </div>
</template>

<script>
import instance from '@/services/instance'
import detailBox from './detail'

export default {
    name: 'activityList',
    components: {
        detailBox,
    },
    data() {
        return {
            tableData: [],
            pageSize: 10,
            currentPage: 1,
            total: 0,
            isShow:true,//true我发布的显示 false我参与的显示
            tableData1: [],
            pageSize1: 10,
            currentPage1: 1,
            total1: 0,
            title:"",
            statusTxtAry: ["未发布","未开始","已结束","下架(系统强制)","活动中"],
            reportUrl:"",
            dialogVisible: false,
            showImg:[],
            fileList:[],
            content:"",
            dialogVisibleDetail:false,
            dialogVisibleDetail1:false,
            detailCont:{},
        }
    },
    created() {
        this.initList();
        this.myActivity();
    },
    methods: {
        goDetail(id,type){
            instance
                .post('/officialWebsiteMgr/resource/activity/detail',{activityId: id})
                .then((res) => {
                    if (res.code == 0) {
                        if(type==1){
                            this.dialogVisibleDetail = true;
                        }else{
                            this.dialogVisibleDetail1 = true;
                        }
                        this.detailCont = res.data;
                    }
                })
        },
        initList() {
            let sdata = {
                startPage: this.currentPage,
                pageSize: this.pageSize,
            }
            instance
                .post('/officialWebsiteMgr/resource/activity/userList', sdata)
                .then((res) => {
                    if (res.code == 0) {
                        this.tableData = res.data.list;
                        this.total = res.data.totalCount;
                    }
                })
        },
        myActivity(){//我参与的活动列表
            let sdata = {
                startPage: this.currentPage1,
                pageSize: this.pageSize1,
            }
            instance
                .post('/officialWebsiteMgr/resource/activity/joinActivityList', sdata)
                .then((res) => {
                    if (res.code == 0) {
                        this.tableData1 = res.data.list;
                        this.total1 = res.data.totalCount;
                    }
                })
        },
        handleSizeChange(val) {
            if(this.isShow){
                this.pageSize = val;
                this.currentPage = 1;
                this.initList();
            }else{
                this.pageSize1 = val;
                this.currentPage1 = 1;
                this.myActivity();
            }
        },
        handleCurrentChange(val) {
            if(this.isShow){
                this.currentPage = val;
                this.initList();
            }else{
                this.currentPage1 = val;
                this.myActivity();
            }
        },
        goPub(id,index){//发布
            this.$confirm("发布活动，是否发布？", "提示", {
                confirmButtonText: "发布",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    instance
                        .post('/officialWebsiteMgr/resource/activity/upSt',{activityId: id,activityStatus:1})
                        .then((res) => {
                            if (res.code == 0) {
                                this.$message({
                                    message: '发布成功！',
                                    type: 'success'
                                });
                                this.tableData[index].activityStatus="1";
                            }
                        })
                })

        },
        goEdit(id) {//编辑
            this.$router.push({
                name: 'activityCreate',
                params: {
                    type: 'edit',
                    id: id,
                },
            })
        },
        goRecord(id){//统计
            this.$router.push({
                name: 'record',
                params: {
                    id: id,
                },
            })
        },
        close(id){//关闭
            instance
                .post('/officialWebsiteMgr/resource/activity/upSt',{activityId: id,activityStatus:2})
                .then((res) => {
                    if (res.code == 0) {
                        this.$message({
                            message: '操作成功，即将刷新数据！',
                            type: 'success'
                        });
                        this.currentPage=1;
                        this.initList();
                    }
                })
        },
        evaluate(id,title){//总评
            instance
                .post('/officialWebsiteMgr/resource/activity/actCrtCmtDetal',{activityId: id})
                .then((res) => {
                    this.dialogVisible=true;
                    this.title=title;
                    if(res.data==null){
                        this.reportUrl = "/officialWebsiteMgr/resource/activity/actCrtCmt";
                    }else{
                        this.reportUrl = "/officialWebsiteMgr/resource/activity/actCrtUpdCmt";
                        this.showImg=res.data.showImg.split(",");
                        this.content=res.data.content;
                        this.activityId=id;
                        for(var i=0;i<this.showImg.length;i++){
                            this.fileList.push({url:this.formatImg(this.showImg[i])})
                        }
                    }
                })
        },
        save(){//保存总评
            if(this.content==""){
                this.$message.error("内容不能为空!");
                return false;
            }
            if(this.showImg.length<=0){
                this.$message.error("没有图集!");
                return false;
            }
            var sdata = {
                activityId: this.activityId,
                content: this.content,
                showImg: this.showImg.join(",")
            }
            instance
                .post(this.reportUrl,sdata)
                .then((res) => {
                    if (res.code == 0) {
                        this.$message({
                            message: '总结成功!',
                            type: 'success'
                        });
                        this.dialogVisible=false;
                    }
                })
        },
        successImg(res){//上传图集成功
            if (res.code == 0) {
                this.showImg.push(res.data);
            }
        },
        handleRemove(file) {//删除图集
            for(var i=0;i<this.showImg.length;i++){
                if(file.response){
                    if(this.showImg[i]==file.response.data){
                        this.showImg.splice(i,1);
                    }
                }else{
                    if(this.formatImg(this.showImg[i])==file.url){
                        this.showImg.splice(i,1);
                    }
                }

            }
        },
        formatImg(url){
            return "/officialWebsiteMgr/resource/file/getImage?fileName="+url;
        },
        finalClose(){//关闭总结
            this.dialogVisible=false;
            this.fileList=[];
        },
        //图片展示
        setUrl(url) {
            // return url;
            if(url.indexOf("http")==0){
                return url;
            }else{
                return '/officialWebsiteMgr/resource/file/getImage?fileName=' + url
            }
        }
    },
}
</script>

<style scoped>
    .my_actlist_point {
        width: 570px;
        height: 100px;
        float: left;
        margin-left: 20px;
    }
    .my_actlist_point h2 {
        font-size: 16px;
        color: #222;
        font-weight: normal;
        height: 20px;
        line-height: 20px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        cursor: pointer;
    }
    .my_actlist_point p:nth-of-type(1) {
        color: #666;
        font-size: 14px;
        height: 20px;
        line-height: 20px;
        margin-top: 10px;
    }
    .my_actlist_point p:nth-of-type(2) {
        height: 20px;
        line-height: 20px;
        margin-top: 5px;
        font-size: 13px;
    }
    .my_actlist_point p:nth-of-type(3){
        height: 20px;
        line-height: 20px;
        margin-top: 5px;
        font-size: 12px;
        color: #999;
    }
    .my_actlist_point p:nth-of-type(3) span{
        margin-right:30px;
    }
    .my_actlist_point p:nth-of-type(2) span{
        padding:2px 10px;
        margin-right:30px;
    }
    .my_actlist_point p:nth-of-type(2) span.st0{
        background-color: rgba(144, 147, 153, 0.1);
        color: #999;
    }
    .my_actlist_point p:nth-of-type(2) span.st4,.my_actlist_point p:nth-of-type(2) span.st1 {
        background-color: rgba(64, 158, 255, 0.1);
        color: #409EFF;
    }
    .my_actlist_point p:nth-of-type(2) span.st2,.my_actlist_point p:nth-of-type(2) span.st3 {
        background-color: rgba(245, 108, 108, 0.1);
        color: #F56C6C;
    }
    .activityDesc{
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
</style>